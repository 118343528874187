var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"data-tables","fluid":"","tag":"section"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"indigo","icon":"mdi-alarm-panel","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"mobile-card-heading"},[_vm._v(" "+_vm._s(_vm.$t('certificate-templates'))+" ")])]},proxy:true}])},[_c('v-text-field',{staticClass:"ml-auto responsive-table-search-input",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{staticClass:"responsive-table",attrs:{"options":_vm.options,"headers":_vm.headers,"items":_vm.certificateTemplates,"search":_vm.search,"footer-props":{
        'items-per-page-all-text': _vm.$t('all-sort'),
        'items-per-page-options': [10, 20, 50, 100, -1],
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('draggable',{attrs:{"tag":"tbody","sort":_vm.options.itemsPerPage === -1 ? true : false,"move":_vm.onMoveCallback,"clone":_vm.onCloneCallback},on:{"end":_vm.onDropCallback},model:{value:(_vm.certificateTemplates),callback:function ($$v) {_vm.certificateTemplates=$$v},expression:"certificateTemplates"}},_vm._l((props.items),function(item){return _c('tr',{key:item.name},[(!_vm.isMobile)?_c('td',{staticStyle:{"cursor":"pointer"},attrs:{"data-label":_vm.headers[0].text}},[(_vm.options.itemsPerPage === -1)?_c('v-icon',[_vm._v(" mdi-drag-vertical ")]):_c('v-icon',[_vm._v(" mdi-pin ")])],1):_vm._e(),_c('td',{attrs:{"data-label":_vm.headers[1].text}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',{attrs:{"data-label":_vm.headers[2].text}},[_vm._v(" "+_vm._s(item.certificateName)+" ")]),_c('td',{attrs:{"data-label":_vm.headers[3].text}},[_vm._v(" "+_vm._s(item.validForYears)+" ")]),_c('td',{attrs:{"data-label":_vm.headers[4].text}},[(item.canCreateReports)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-alert-circle ")])],1),(_vm.isMobile)?_c('td'):_vm._e(),_c('td',{staticStyle:{"text-align":"right"},attrs:{"data-label":_vm.headers[5].text}},[(_vm.userIsAllowedToEdit)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.clickUpdateCertificateTemplate(item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pen ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.edit-certificate-template')))])]):_vm._e(),(_vm.userIsAllowedToEdit)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"#db3236"},on:{"click":function($event){return _vm.clickDeleteCertificateTemplate(item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.delete-certificate-template')))])]):_vm._e()],1)])}),0)]}}])}),_c('v-divider'),_c('v-row',{staticClass:"mx-auto py-6",attrs:{"justify":"end","align":"center"}},[(_vm.userIsAllowedToEdit && !_vm.isMobile && _vm.options.itemsPerPage === -1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"success","fab":"","small":""},on:{"click":_vm.clickSaveSort}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-sort ")])],1)]}}],null,false,3575951602)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.save-sorting')))])]):_vm._e(),(_vm.userIsAllowedToEdit)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"green","fab":"","small":""},on:{"click":_vm.clickCreateCertificateTemplate}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}}],null,false,1931953456)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.add-certificate-template')))])]):_vm._e()],1)],1),_c('v-dialog',{model:{value:(_vm.createCertificateTemplateDialog),callback:function ($$v) {_vm.createCertificateTemplateDialog=$$v},expression:"createCertificateTemplateDialog"}},[(_vm.createCertificateTemplateDialog)?_c('certificate-template-wizard',{attrs:{"selected-certificate-template":_vm.selectedCertificateTemplate},on:{"wizardcanceled":function($event){_vm.createCertificateTemplateDialog = false},"wizardfinished":_vm.certificateTemplateCreated}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }