


































































































































































































  import { debounceInput, isMobileScreenWidth } from '@/shared/helpers'
  import { CertificateTemplate, ICertificateTemplate } from '@/shared/model/certificateTemplate'
  import {
    CertificationAuthority,
    CertificationAuthorityRole,
    ICertificationAuthority,
  } from '@/shared/model/certificationAuthority.model'
  import { User, UserRole } from '@/shared/model/user.model'
  import { types } from '@/store/utils/resource'
  import axios from 'axios'
  import Vue from 'vue'
  import { mapActions, mapGetters } from 'vuex'
  import draggable from 'vuedraggable'

  export default Vue.extend({
    name: 'CertificateTemplatesTable',

    components: {
      CertificateTemplateWizard: () => import('./CertificateTemplateWizard.vue'),
      draggable,
    },

    data () {
      return {
        options: {} as any,

        dialog: false,
        selectedUser: new User(),

        search: '',
        certificateTemplates: [] as ICertificateTemplate[],
        totalCertificateTemplates: 0,
        createCertificateTemplateDialog: false,
        debounce: undefined as number | undefined,
        isMobile: isMobileScreenWidth(),

        activeCertificationAuthority: undefined as ICertificationAuthority | undefined,

        selectedCertificateTemplate: undefined as ICertificateTemplate | undefined,
      }
    },

    computed: {
      ...mapGetters({
        account: 'account',
        certificationAuthority: 'selectedCertificationAuthority',
      }),
      headers (): any {
        const list = [
          {
            text: this.$t(''),
            value: 'sortIndex',
          },
          {
            text: this.$t('namename'),
            value: 'name',
          },
          {
            text: this.$t('name'),
            value: 'certificateName',
          },
          {
            text: this.$t('valid-for'),
            value: 'validForYears',
          },
          {
            text: this.$t('can-create-reports'),
            value: 'canCreateReports',
          },
          {
            sortable: false,
            text: this.$t('actions'),
            value: 'actions',
            align: 'end',
          },
        ]
        return list
      },
      userIsAllowedToEdit: function () {
        if (this.certificationAuthority && this.account) {
          let userIsCertAuthAdmin = false
          const userFromCertAuth = this.certificationAuthority.users.find((x: any) => x.user._id === this.account._id)

          if (userFromCertAuth && userFromCertAuth.authorityRoles) {
            userIsCertAuthAdmin = userFromCertAuth.authorityRoles.includes(CertificationAuthorityRole.authorityAdmin)
          }
          if (userIsCertAuthAdmin || this.account.roles.includes(UserRole.admin)) {
            return true
          }
        }
        return false
      },
    },
    watch: {
      certificationAuthority: {
        async handler () {
          await this.getAllCertificateTemplatesWithVDataTable()
        },
      },
    },
    mounted () {
      if (this.certificationAuthority && !this.certificateTemplates.length) {
        this.getAllCertificateTemplatesWithVDataTable()
      }
    },
    methods: {
      async getAllCertificateTemplatesWithVDataTable () {
        const query = {
          l: 0,
          lo: 0,
          sf: this.options.sortBy[0] || 'sortIndex',
          so: this.options.sortDesc[0] ? 'desc' : 'asc',
          search: { search: this.search },
        }

        await this.getList(query)
      },
      async getList ({ l, lo, sf, so, search = {} }: any) {
        try {
          let id = this.$route.params.id
          if (!id && this.certificationAuthority) {
            id = this.certificationAuthority._id
          }
          if (id) {
            const result = await axios.get(`/api/certificationAuthority/${id}/template`, {
              params: { l: l, lo: lo, sf, so, ...search },
            })
            if (result && result.data && result.data.items) {
              this.certificateTemplates = result.data.items
              this.totalCertificateTemplates = result.data.total

              this.$store.commit(`certificateTemplateStore/${types.GET_LIST.SUCCESS}`, result)
            }
          }
        } catch (err) {
          console.error(err)
        }
      },
      certificateTemplateCreated () {
        this.createCertificateTemplateDialog = false
        this.getAllCertificateTemplatesWithVDataTable()
      },
      clickCreateCertificateTemplate (item: ICertificateTemplate) {
        this.selectedCertificateTemplate = undefined
        this.createCertificateTemplateDialog = true
      },
      clickUpdateCertificateTemplate (item: ICertificateTemplate) {
        this.selectedCertificateTemplate = item
        this.createCertificateTemplateDialog = true
      },
      clickDeleteCertificateTemplate (item: ICertificateTemplate) {
        this.$confirm(
          `${this.$t('dialog.do-you-really-want-the-certification-authority-to')}
<strong>${item.name}</strong>
${this.$t('dialog.delete')}`,
          {
            color: 'error',
            buttonTrueText: this.$t('yes').toString(),
            buttonFalseText: this.$t('no').toString(),
            title: this.$t('delete-certification-authority').toString(),
          },
        ).then(async (res) => {
          if (res) {
            if (item._id) {
              await this.deleteCertificateTemplate(item._id)
              this.getAllCertificateTemplatesWithVDataTable()
            } else {
              console.error('Tried to delete user without id, this should not happen')
            }
          }
        })
      },
      async deleteCertificateTemplate (id: string) {
        await axios.delete(`/api/certificationAuthority/${this.certificationAuthority._id}/template/${id}`)
      },
      onCloneCallback (item: any) {
        // Create a fresh copy of item
        const cloneMe = JSON.parse(JSON.stringify(item))

        return cloneMe
      },
      onMoveCallback (evt: any, originalEvent: any) {
        const item = evt.draggedContext.element
        const itemIdx = evt.draggedContext.futureIndex

        // console.log('onMoveCallback')

        if (item.locked) {
          return false
        }

        return true
      },
      onDropCallback (evt: any, originalEvent: any) {
      // console.log('onDropCallback')
      },
      async clickSaveSort () {
        for (let x = 0; x < this.certificateTemplates.length; x++) {
          this.certificateTemplates[x].sortIndex = x
          await this.updateCertTemplate(this.certificateTemplates[x])
        }
        this.$toast(this.$t('dialog.certificate-template-sort-save-success').toString())
      },

      async updateCertTemplate (template: CertificateTemplate) {
        await this.axios
          .put(`/api/certificationauthority/${template.certificationAuthority}/template/${template._id}`, template)
          .then((result) => {
            if (!result) {
              console.log('smth went wrong')
            }
          })
      },
    },
  })
